import { useQuery } from "@tanstack/react-query"
import { clsx } from "clsx"

import { getCurrentUserQuery } from "@src/api/user"
import { Image } from "@src/components/atoms/Image"
import { Skeleton } from "@src/components/atoms/Skeleton"

export type UserProfileProps = {
  className?: string
}

export const UserProfile = ({ className }: UserProfileProps) => {
  const { data, isLoading } = useQuery(getCurrentUserQuery())

  if (isLoading || !data?.photoUrl) {
    return <Skeleton className={clsx([className, "rounded-full"])} />
  }

  return (
    <Image
      className={clsx([className, "rounded-full"])}
      src={data?.photoUrl}
      alt="User Profile Photo"
    />
  )
}
