import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/application-definitions/',
)({
  component: () => (
    <div>
      Hello /community/$communityId/admin-panel/application-definitions!
    </div>
  ),
})
