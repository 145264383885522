import { useState, useEffect } from "react"
import { useMutation } from "@tanstack/react-query"
import { getToken } from "@src/api/user"
import { useAuth } from "@src/hooks"

export const LoginForm = () => {
  const { setToken } = useAuth()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const mutation = useMutation({
    mutationFn: getToken,
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    mutation.mutate({ username, password })
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      setToken(mutation.data.access_token)
    }
  }, [mutation.isSuccess, mutation.data, setToken])

  return (
    <form onSubmit={handleSubmit} className="max-w-[400px]">
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="mb-3 block"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="mb-3 block"
      />
      <button type="submit" className="mb-3 block">
        Login
      </button>

      {mutation.isSuccess ? <div>{JSON.stringify(mutation.data)}</div> : null}
      {mutation.isError ? (
        <div className="text-red-500">{mutation.error.message}</div>
      ) : null}
    </form>
  )
}
