import { queryOptions } from "@tanstack/react-query"
import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackApiListPromise } from "@src/utils/types"

export enum ApplicationStatus {
  Apply = 0,
  Draft = 1,
  Pending = 2,
  Accepted = 3,
  Denied = 4,
}

export const applicationKeys = {
  all: ["applications"],
  userList: () => [...applicationKeys.all, "list", "user"],
}

export const applicationDefinitionKeys = {
  all: ["applicationDefinitions"],
  communityList: (id?: number) => [
    ...applicationDefinitionKeys.all,
    "list",
    "community",
    id,
  ],
}

export const getCommunityApplicationDefinitions = async (id: number) => {
  const data = await client.GET("/api/Community/{id}/ApplicationDefinitions", {
    params: {
      path: { id },
    },
  })

  return data
}

export type CommunityApplicationDetail = UnpackApiListPromise<
  ReturnType<typeof getCommunityApplicationDefinitions>
>

export const getCommunityApplicationDefinitionsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityApplicationDefinitions,
    queryKeys: applicationDefinitionKeys.communityList(id),
    unsafeQueryFnArgs: [id],
  })

export const getCurrentUserApplications = async () => {
  const data = await client.GET("/api/Application/GetCurrentUserApplications")

  return data
}

export const getCurrentUserApplicationsQuery = () =>
  queryOptions({
    queryKey: ["currentUserApplications", "list"],
    queryFn: () => getCurrentUserApplications(),
  })

export type CurrentUserApplication = UnpackApiListPromise<
  ReturnType<typeof getCurrentUserApplications>
>
