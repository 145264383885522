import { clsx } from "clsx"

type MobileItemProps = {
  children: React.ReactNode
  className?: string
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export const MobileItem = ({
  children,
  className,
  onClick,
}: MobileItemProps) => {
  return (
    <li
      onClick={onClick}
      className={clsx([
        className,
        "flex h-14 flex-1 items-center justify-center",
        "border-t border-pearl-lighter hover:border-blue",
        "cursor-pointer text-center text-pearl-dark hover:text-blue",
      ])}
    >
      {children}
    </li>
  )
}
