import { jwtDecode } from "jwt-decode"

export const validateJwt = (token?: string): boolean => {
  if (!token) return false

  try {
    const decoded = jwtDecode(token)
    // Check if the token is expired
    if (decoded.exp && decoded.exp < Date.now() / 1000) {
      return false
    }

    return true
  } catch {
    return false
  }
}
