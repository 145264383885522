import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

export interface AppState {
  token: string | undefined
  setToken: (newToken: string) => void
  removeToken: () => void
}

export const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      token: "",
      setToken: (newToken: string) => set(() => ({ token: newToken })),
      removeToken: () => set(() => ({ token: "" })),
    }),
    {
      name: "app-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
