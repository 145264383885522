import { LoginPage } from "@src/components/pages/LoginPage"

export const UnauthLayout = () => {
  return (
    <div className="min-h-screen p-6">
      <h1 className="text-center">Babele</h1>
      <LoginPage />
    </div>
  )
}
