import { AxiosError } from "axios"
import { paths } from "./schema"
import { useAppStore } from "../state"
import { createClient } from "../../lib/openapi-axios"
import { ProcessPaths } from "@src/utils/types"

export const client = createClient<ProcessPaths<paths>>({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
})

client.axiosInstance.interceptors.request.use((config) => {
  const token = useAppStore.getState().token

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`
  }

  return config
})

client.axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      useAppStore.setState({ token: "" })
      window.location.href = "/login"
    }
    return Promise.reject(error)
  },
)
