import { clsx } from "clsx"

export type SkeletonProps = {
  className?: string
}

export const Skeleton = ({ className }: SkeletonProps) => {
  return (
    <div
      role="status"
      aria-live="polite"
      className={clsx(["animate-pulse bg-pearl-lighter", className])}
    />
  )
}
