import { createFileRoute, Outlet, redirect } from "@tanstack/react-router"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { getAuthCookie } from "@src/utils/cookies"
import { dynamicActivate } from "@src/utils/locales"
import { getCurrentUserQuery } from "@src/api/user"

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ location, context }) => {
    const { useAppStore } = context
    const token = useAppStore.getState().token

    if (!token) {
      const authCookie = getAuthCookie()

      if (authCookie) {
        useAppStore.setState({ token: authCookie })
        return
      }

      throw redirect({
        to: "/login",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      })
    }
  },
  loader: async ({ context }) => {
    try {
      await context.queryClient.ensureQueryData(getCurrentUserQuery())
    } catch (error) {
      console.log("Error logging in")
    }
  },
  component: AuthWrapper,
  // TODO: Add loading and error components. Investigate useSuspenseQuery vs useQuery
})

function AuthWrapper() {
  const { data } = useSuspenseQuery(getCurrentUserQuery())

  useEffect(() => {
    const language = data?.language?.slice(0, 2) ?? "en"
    dynamicActivate(language)
  }, [data])
  return <Outlet />
}

// Add an interceptor to the client that if a request fails with a 401
// it sets the token to null and this is caught in this route and redirects
// to the login page

// We need to load the profile data here using react query and set the language
// based on the user's profile. Maybe store it in the store? And here we dynamically
// load the message catalog https://lingui.dev/guides/dynamic-loading-catalogs
// the english version probably needs to be loaded before this, maybe in the main file?
// but we can load that one dynamically as well
