import { STAGING_URL, PROD_URL } from "./consts"

export const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts && parts.length === 2) return parts.pop()?.split(";").shift()
}

export const getAuthCookie = (): string | undefined => {
  if (window.location.hostname === STAGING_URL) {
    return getCookie("stagingToken")
  }

  if (window.location.hostname === PROD_URL) {
    return getCookie("prodToken")
  }
}
