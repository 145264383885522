import { queryOptions } from "@tanstack/react-query"
import { client } from "./client"
import { components } from "./schema"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export const userKeys = {
  all: ["users"],
  current: () => [...userKeys.all, "current"],
  currentStuff: () => [...userKeys.current(), "stuff"],
}

export type LoginRequest = components["schemas"]["LoginRequestDto"]

export type TokenResponse = {
  access_token: string
  username: string
  token_type: string
}

export const getCurrentUser = async () => {
  const data = await client.GET("/api/User/GetCurrent")

  return data
}

export type CurrentUser = UnpackPromise<ReturnType<typeof getCurrentUser>>

export const getCurrentUserQuery = () =>
  queryOptions({
    queryKey: ["users", "current"],
    queryFn: getCurrentUser,
  })

export const getCurrentUserCommunities = async () => {
  const data = await client.GET("/api/User/GetMyCommunities")

  return data
}

export type UserCommunitiesList = UnpackPromise<
  ReturnType<typeof getCurrentUserCommunities>
>
export type UserCommunities = UnpackArray<UserCommunitiesList>

export const getCurrentUserCommunitiesQuery = () =>
  queryOptions({
    queryKey: ["users", "current", "communities"],
    queryFn: getCurrentUserCommunities,
  })

export const getToken = async (
  request: LoginRequest,
): Promise<TokenResponse> => {
  const data = await client.POST("/token", {
    body: request,
  })

  return data as unknown as TokenResponse
}

export const getCurrentUserStuff = async () => {
  const data = await client.GET("/api/User/GetMyStuff")

  return data
}

export type CurrentUserStuff = UnpackPromise<
  ReturnType<typeof getCurrentUserStuff>
>

export const getCurrentUserStuffQuery = () =>
  queryOptions({
    queryKey: userKeys.currentStuff(),
    queryFn: getCurrentUserStuff,
  })
