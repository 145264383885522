import { queryOptions } from "@tanstack/react-query"

/* eslint-disable @typescript-eslint/no-explicit-any */
export const safeQueryOptions = <Args extends any[], Result>({
  queryFn,
  unsafeQueryFnArgs,
  queryKeys,
}: {
  queryFn: (...args: Args) => Promise<Result>
  unsafeQueryFnArgs: { [K in keyof Args]: Args[K] | null | undefined }
  queryKeys: any[]
}) => {
  const hasInvalidArgs = unsafeQueryFnArgs.some(
    (arg) => arg === null || arg === undefined,
  )

  let safeQueryFn: () => Promise<Result> = async () => ({}) as Result

  if (!hasInvalidArgs) {
    const safeApiCall = queryFn as (...args: Args) => Promise<Result>
    // Safe to typecast here, since we've already checked that the args are valid
    safeQueryFn = () => safeApiCall(...(unsafeQueryFnArgs as Args))
  }

  return queryOptions({
    queryKey: [...queryKeys],
    queryFn: safeQueryFn,
    enabled: !hasInvalidArgs,
  })
}
