import { lazy, Suspense } from "react"
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router"
import { RouterContext } from "../main"

// Only load Tanstack router devtools in development mode
const LazyTanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : lazy(() =>
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <Outlet />
      <Suspense>
        <LazyTanStackRouterDevtools initialIsOpen={false} />
      </Suspense>
    </>
  ),
})
