import { Link } from "@tanstack/react-router"

import { IconType } from "@src/config/icons"
import { Image } from "@src/components/atoms/Image"
import { Icon } from "@src/components/atoms/Icon"

type HeaderDrawerSectionProps = {
  name: string
  icon: IconType
  path: "/community" | "/applications" | "/projects"
  items?: {
    id: number
    name: string | null | undefined
    logo?: string | null
  }[]
}

export const HeaderDrawerSection = ({
  name,
  icon,
  path,
  items,
}: HeaderDrawerSectionProps) => {
  return (
    <div className="flex flex-col gap-3">
      <h6 className="flex items-center gap-3 font-bold text-pearl">
        <Icon icon={icon} className="!text-heading5" />
        {name}
      </h6>

      <div className="flex flex-col gap-2">
        {items?.map(
          ({ id, name, logo }) =>
            name && (
              <div className="flex items-center gap-2" key={id}>
                <Image
                  src={logo}
                  alt={name}
                  className="h-6 w-6 rounded-lg text-paragraph-small"
                />

                {path === "/applications" && (
                  <Link
                    className="cursor-pointer text-paragraph-medium font-medium text-pearl"
                    to="/applications/$applicationId"
                    params={{ applicationId: String(id) }}
                  >
                    {name}
                  </Link>
                )}
                {path === "/projects" && (
                  <Link
                    className="cursor-pointer text-paragraph-medium font-medium text-pearl"
                    to="/projects/$projectId"
                    params={{ projectId: String(id) }}
                  >
                    {name}
                  </Link>
                )}
                {path === "/community" && (
                  <Link
                    className="cursor-pointer text-paragraph-medium font-medium text-pearl"
                    to="/community/$communityId"
                    params={{ communityId: String(id) }}
                  >
                    {name}
                  </Link>
                )}
              </div>
            ),
        )}
      </div>
    </div>
  )
}
