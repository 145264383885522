import { clsx } from "clsx"
import { getFullImageUrl } from "@src/utils/image"
import { useMemo } from "react"
import { PlaceholderImage } from "@src/components/atoms/PlaceholderImage"

export type ImageProps = {
  src?: string | null
  alt: string
  className?: string
  lazy?: boolean
}

export const Image = ({ src, alt, className, lazy = false }: ImageProps) => {
  const fullSrc = useMemo(() => getFullImageUrl(src || ""), [src])

  if (!src) {
    return <PlaceholderImage name={alt} className={className} />
  }

  return (
    <img
      src={fullSrc}
      alt={alt}
      className={clsx(["object-cover", className])}
      loading={lazy ? "lazy" : "eager"}
    />
  )
}
