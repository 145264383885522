import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_auth/_community/community/$communityId/_admin-panel/admin-panel/application-definitions/$applicationDefinitionId',
)({
  component: () => (
    <div>
      Hello
      /community/$communityId/admin-panel/application-definitions/$applicationDefinitionId!
    </div>
  ),
})
