import { createFileRoute, Outlet } from "@tanstack/react-router"
import { usePermissions } from "@src/hooks/usePermissions"
import { UnauthorizedPage } from "@src/components/pages/UnauthorizedPage"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel",
)({
  component: AdminPanelWrapper,
})

function AdminPanelWrapper() {
  const { hasPermissions } = usePermissions()

  if (!hasPermissions("admin-panel")) {
    return <UnauthorizedPage />
  }

  return <Outlet />
}
