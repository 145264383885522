import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise } from "@src/utils/types"

export enum UserInvitationPolicy {
  Undefined = 0,
  Anyone = 1,
  OnlyPlatformUsers = 2,
  OnlyCommunityUsers = 3,
  None = 4, // only community admins can invite
}

export enum UserJoinCommunitySetting {
  JoinWithoutApproval = 0,
  JoinWithApproval = 1,
  JoinWithInvitation = 2,
}

export enum TaskUnlock {
  Calendar = 0,
  Freestyle = 1,
  Sequential = 2,
  Stagegate = 3,
}

export enum DevelopmentStageType {
  None = 0,
  Default = 1,
  CustomType = 2,
}

export enum CommunityType {
  Public = 0,
  Private = 1,
  Hybrid = 2,
}

export const communityKeys = {
  all: ["community"],
  overview: (id?: number) => [...communityKeys.all, "overview", id, "overview"],
}

export const getCommunityOverview = async (id: number) => {
  const data = await client.GET("/api/Community/GetOverview", {
    params: {
      query: { id },
    },
  })

  return data
}

export type CommunityOverview = UnpackPromise<
  ReturnType<typeof getCommunityOverview>
>

export const getCommunityOverviewQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityOverview,
    queryKeys: communityKeys.overview(id),
    unsafeQueryFnArgs: [id],
  })
