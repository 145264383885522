import { Outlet } from "@tanstack/react-router"
import { Header } from "@src/components/organisms/Header"
import { CommunityMenu } from "@src/components/organisms/Menu/CommunityMenu"
import { MobileMenu } from "@src/components/organisms/Menu/MobileMenu"

export const CommunityLayout = () => {
  return (
    <div className="min-h-dvh w-full">
      <Header />
      <div className="flex min-h-[calc(100dvh-84px)]">
        <CommunityMenu />
        <main className="p-8">
          <Outlet />
        </main>
      </div>
      <MobileMenu />
    </div>
  )
}
