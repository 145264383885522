import { useNavigate } from "@tanstack/react-router"
import { clsx } from "clsx"

import { Icon } from "@src/components/atoms/Icon"
import { IconType } from "@src/config/icons"

import { Button as HeadlessButton } from "@headlessui/react"

export type MenuItemProps = {
  name?: string
  link: string
  icon: IconType
  className?: string
  notification?: boolean
}

export const MenuItem = ({
  name,
  link,
  icon,
  className,
  notification = false,
}: MenuItemProps) => {
  const navigate = useNavigate()

  return (
    <HeadlessButton
      className={clsx([
        className,
        "flex w-full items-center gap-4",

        "rounded-lg",

        "text-pearl-dark hover:bg-blue-light/50 hover:text-blue",
      ])}
      onClick={() => {
        navigate({ to: link })
      }}
    >
      {icon && <Icon size="large" icon={icon} />}

      {name}

      {notification && (
        <span role="status" className="h-2 w-2 rounded-full bg-red"></span>
      )}
    </HeadlessButton>
  )
}
