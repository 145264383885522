import { useQuery } from "@tanstack/react-query"

import { getCurrentUserStuffQuery } from "@src/api/user"
import { getCurrentUserApplicationsQuery } from "@src/api/application"
import { CommunityOverview } from "@src/api/community"
import { Drawer } from "@src/components/organisms/Drawer"
import { Image } from "@src/components/atoms/Image"
import { HeaderDrawerSection } from "./HeaderDrawerSection"
import { hasDefinedId } from "@src/utils/types"

type HeaderDrawerProps = {
  opened: boolean
  community: CommunityOverview
  closeMenu: () => void
}

export const HeaderDrawer = ({
  opened,
  closeMenu,
  community,
}: HeaderDrawerProps) => {
  const { data: myStuff } = useQuery(getCurrentUserStuffQuery())
  const { data: applications } = useQuery(getCurrentUserApplicationsQuery())

  return (
    <Drawer position="left" open={opened} closeDrawer={() => closeMenu()}>
      <div className="flex h-full flex-col">
        <div className="relative h-64 pt-32">
          {community?.mainImage && (
            <Image
              className="absolute left-0 top-0 h-full w-full"
              src={community?.mainImage}
              alt={community?.name || "" + " logo image"}
            />
          )}
          <div className="relative bottom-0 z-10 flex flex-col items-center justify-center gap-2 text-pearl">
            <Image
              className="h-7 w-7 rounded-lg lg:h-12 lg:w-12"
              src={community?.logoImage}
              alt={community?.name || "" + " logo image"}
            />
            <span className="flex flex-col items-center">
              <span className="font-semibold lg:text-paragraph">
                {community?.name}
              </span>
              <span className="font-light lg:text-paragraph-medium">
                {community?.city}, {community?.countryName}
              </span>
            </span>
          </div>
        </div>

        <nav className="flex h-full flex-1 flex-col gap-8 overflow-y-auto bg-blue p-6 lg:p-12">
          <HeaderDrawerSection
            name="My communities"
            icon="tooltip_2"
            path="/community"
            items={myStuff?.communities
              ?.filter(hasDefinedId)
              ?.map(({ id, name, logoImage }) => ({
                id,
                name,
                logo: logoImage,
              }))}
          />

          <HeaderDrawerSection
            name="My projects"
            icon="rocket_launch"
            path="/projects"
            items={myStuff?.projects
              ?.filter(hasDefinedId)
              .map(({ id, name, logoImage }) => ({
                id,
                name,
                logo: logoImage,
              }))}
          />

          <HeaderDrawerSection
            name="My applications"
            icon="bookmarks"
            path="/applications"
            items={applications?.list
              ?.filter(hasDefinedId)
              ?.map(
                ({ id, applicationDefinitionName, communityLogoImage }) => ({
                  id,
                  name: applicationDefinitionName,
                  logo: communityLogoImage,
                }),
              )}
          />
        </nav>
      </div>
    </Drawer>
  )
}
