import { IconType } from "@src/config/icons"

export const projectMenu: MenuItemType[] = [
  {
    name: "Forum",
    link: "/forum",
    icon: "tooltip_2",
  },
  {
    name: "Members",
    link: "/members",
    icon: "group",
  },
  {
    name: "Resources",
    link: "/resources",
    icon: "folder_copy",
  },
  {
    name: "Events",
    link: "/events",
    icon: "event",
  },
  {
    name: "Requests",
    link: "/request",
    icon: "live_help",
  },
  {
    name: "Applications",
    link: "/applications",
    icon: "page_info",
  },
]

export const adminMenu: MenuItemType[] = [
  {
    name: "General",
    link: "/general",
    icon: "tune",
  },
  {
    name: "Forms",
    link: "/forms",
    icon: "description",
  },
  {
    name: "Programs",
    link: "/programs",
    icon: "lightbulb",
  },
  {
    name: "Applications",
    link: "/applications",
    icon: "bookmarks",
  },
  {
    name: "Startup",
    link: "/startup",
    icon: "rocket_launch",
  },
  {
    name: "Coaching",
    link: "/coaching",
    icon: "forum",
  },
  {
    name: "Permissions",
    link: "/permissions",
    icon: "encrypted",
  },
  {
    name: "Pages",
    link: "/pages",
    icon: "home",
  },
  {
    name: "Statistics",
    link: "/statistics",
    icon: "monitoring",
  },
]

export type MenuItemType = {
  name: string
  link: string
  icon: IconType
}
