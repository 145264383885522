/* eslint-disable @typescript-eslint/no-explicit-any */
export type UnpackArray<T> = T extends Array<infer U> ? U : T
export type UnpackPromise<T> = T extends Promise<infer U> ? U : T
export type UnpackApiList<T> = T extends { list?: infer U | null } ? U : never
export type UnpackApiListPromise<T> = UnpackArray<
  UnpackApiList<UnpackPromise<T>>
>

// The original schema files has one issue: the requestBody property is always optional even
// when it should be required. So the following 3 utility types do 2 things: remove the
// requestBody completely if it's never and make it required if it can have a value in the
// schema
type RemoveNeverRequestBody<T> = T extends { requestBody?: never }
  ? Omit<T, "requestBody">
  : T

type EnforceDefinedRequestBody<T> = T extends { requestBody?: infer U }
  ? Omit<T, "requestBody"> & { requestBody: U }
  : T

export type ProcessPaths<T> = {
  [P in keyof T]: {
    [M in keyof T[P]]: EnforceDefinedRequestBody<
      RemoveNeverRequestBody<T[P][M]>
    >
  }
}

// Typeguard to check if an item has an id property that is not undefined and infer the correct type
// if the id is defined
export function hasDefinedId<T extends { id?: any }>(
  item: T,
): item is T & { id: NonNullable<T["id"]> } {
  return item.id !== undefined
}
