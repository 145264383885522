import { useMemo, useEffect } from "react"
import { useAppStore } from "@src/state"
import { validateJwt } from "@src/utils/token"

// Maybe https://tkdodo.eu/blog/testing-react-query#for-custom-hooks
export const useAuth = () => {
  const token = useAppStore((state) => state.token)
  const isValid = useMemo(() => validateJwt(token), [token])
  const setToken = useAppStore((state) => state.setToken)
  const removeToken = useAppStore((state) => state.removeToken)

  useEffect(() => {
    if (!isValid) {
      removeToken()
    }
  }, [isValid, removeToken])

  return useMemo(
    () => ({ isAuthenticated: isValid, setToken, removeToken }),
    [isValid, setToken, removeToken],
  )
}
