import { useEffect } from "react"
import { useLocation, useNavigate } from "@tanstack/react-router"
import { useAuth } from "@src/hooks"
import { LoginForm } from "../LoginForm"

export const LoginPage = () => {
  const { isAuthenticated } = useAuth()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      const params = location.search
      const searchParams = new URLSearchParams(params)
      const navigateTo = searchParams.get("redirect") || "/"
      navigate({ to: navigateTo })
    }
  }, [isAuthenticated, navigate, location])

  return (
    <div className="m-11 flex w-full items-center justify-center">
      <LoginForm />
    </div>
  )
}
