import { Fragment } from "react"
import {
  Transition,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react"
import { clsx } from "clsx"

import { IconButton } from "@src/components/atoms/IconButton"

export type DrawerProps = {
  title?: string
  open: boolean
  position?: "bottom" | "left"
  closeDrawer: () => void
  children: React.ReactNode
}

export const Drawer = ({
  title,
  open,
  position = "bottom",
  closeDrawer,
  children,
}: DrawerProps) => {
  let translateFrom = "translate-y-full"
  let translateTo = "translate-y-0"

  if (position === "left") {
    translateFrom = "-translate-x-full"
    translateTo = "translate-x-0"
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog onClose={closeDrawer} className="fixed inset-0 z-50">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom={translateFrom}
          enterTo={translateTo}
          leave="ease-in duration-200"
          leaveFrom={translateTo}
          leaveTo={translateFrom}
        >
          <DialogPanel
            className={clsx([
              "fixed bottom-0 left-0 right-0 rounded-t-2xl bg-white shadow-lg", // base
              position === "left" &&
                "right-auto h-full w-full rounded-r-2xl rounded-t-none lg:w-[350px]",
            ])}
          >
            {title && (
              <DialogTitle className="flex items-center justify-between border-b border-pearl-lighter p-4 text-paragraph uppercase text-pearl-light">
                {title}
              </DialogTitle>
            )}

            <IconButton
              icon="close"
              onClick={closeDrawer}
              className="absolute right-4 top-4 z-10 text-paragraph"
              variant="ternary"
            />

            {children}
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}

export default Drawer
