import {
  Button as HeadlessButton,
  ButtonProps as HeadlessButtonProps,
} from "@headlessui/react"
import { clsx } from "clsx"

import { Icon } from "@src/components/atoms/Icon"
import { IconType } from "@src/config/icons"

const styles = {
  base: [
    // base
    "inline-flex items-center border cursor-pointer disabled:pointer-events-none rounded-lg",

    // sizing
    "p-0",

    // focus
    "focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500",

    // disabled
    "data-[disabled]:opacity-25",
  ],

  primary: [
    // base
    "bg-blue text-white border-blue font-semibold",

    // actions
    "hover:bg-blue-dark hover:border-blue-dark active:border-blue-lighter active:bg-blue-lighter",
  ],

  secondary: [
    // base
    "bg-transparent text-pearl-dark border-pearl-light font-semibold",

    // actions
    "hover:bg-pearl hover:text-pearl-darker",
  ],

  ternary: [
    // base
    "bg-pearl/20 border-transparent text-pearl-light font-semibold",

    // actions
    "hover:bg-pearl/10 active:bg-transparent active:text-blue-dark",
  ],

  text: [
    // base
    "bg-transparent text-pearl-light border-transparent",

    // actions
    "hover:text-blue-dark active:text-blue-dark",
  ],
}

export type IconButtonProps = {
  variant?: "primary" | "secondary" | "ternary" | "text"
  small?: boolean
  className?: string
  icon: IconType
} & HeadlessButtonProps

export const IconButton = ({
  variant = "primary",
  className,
  icon,
  small = false,
  ...props
}: IconButtonProps) => {
  const classes = clsx(styles.base, styles[variant], className)

  return (
    <HeadlessButton className={classes} {...props}>
      {icon && (
        <Icon
          className="leading-none"
          size={small ? "default" : "large"}
          icon={icon}
        />
      )}
    </HeadlessButton>
  )
}
